<template>
  <CModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    size="lg"
    color="primary"
  >
    <template #header>
      <h2 class="modal-title">
        Process Defects - {{ data.product.type }} #{{ data.product.id }}
      </h2>
      <CButtonClose @click="hide()" class="text-white" />
    </template>
    <CRow class="parts justify-content-center">
      <CCol sm="12">
        <h2>1. Select Category</h2>
      </CCol>
      <CCol
        class="part p-2"
        sm="2"
        v-for="part_category in part_categories"
        :key="part_category.id"
        @click="defect.part_category_id = part_category.id; getParts()"
        :class="defect.part_category_id == part_category.id ? 'active' : ''"
      >
        <img width="50" class='' :src="storageURL + part_category.image" />
        <p>{{ part_category.name }}</p>
      </CCol>
    </CRow>
    <CRow class="parts justify-content-center">
      <CCol sm="12">
        <h2>2. Select Part</h2>
      </CCol>
      <CCol
        class="part p-2"
        sm="2"
        v-for="part in parts"
        :key="part.id"
        @click="defect.part = part"
        :class="defect.part.id == part.id ? 'active' : ''"
      >
        <img width="50" class='' :src="storageURL + part.image" />
        <p>{{ part.name }}</p>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <p><strong>Defect Note</strong></p>
        <CTextarea
          description="This note will be placed on the defect information"
          v-model="defect.note"
        />
      </CCol>
    </CRow>
    <template #footer>
      <CButton @click="hide()" color="danger">Cancel</CButton>
      <CButton :disabled="defect.part.id? false:true" @click="add()" color="success">Add</CButton>
    </template>
  </CModal>
</template>

<script>
import PartAPI from "/app/src/api/part.js";
import PartCategoryAPI from "/app/src/api/part-category.js";
import DefectAPI from "/app/src/api/defect.js";

export default {
  name: "Defects",
  props: ["data", "show"],
  data: () => {
    return {
      partAPI: new PartAPI(),
      partCategoryAPI: new PartCategoryAPI(),
      defectAPI: new DefectAPI(),
      parts: [],
      part_categories: [],
      showModal: false,
      defects: [],
      defect: {
        part: {
          part_category_id: null,
          part_id: null,
        },
        note: ''
      },
      storageURL: process.env.VUE_APP_STORAGE_URL,
    };
  },
  created: function () {
    this.getPartCategories();
    this.showModal = this.show;
    this.defect.product_id = this.data.product_id;
    this.defect.booking_row_id = this.data.id;
  },
  methods: {
    getPartCategories: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.partCategoryAPI
        .all()
        .then((part_categories) => {
          this.$store.dispatch("stopLoading");
          self.part_categories = part_categories;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    getParts: function () {
      this.$store.dispatch("loading");
      let self = this;
      this.partAPI
        .byCategoryID(this.defect.part_category_id)
        .then((parts) => {
          this.$store.dispatch("stopLoading");
          self.parts = parts;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    
    add() {
      this.$emit("input", this.defect);
      this.defect = {
        part_category_id: null,
        part_id: null,
        note: ''
      };  
      this.$emit("hide");
    },

    hide() {
      this.$emit("hide");
    },
  },
  watch: {
    show(val, oldVal) {
      this.showModal = val;
    },
    data(val, oldVal) {
      this.data = val;
    },
  },
};
</script>

<style scoped>
.part {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100px;
  text-align: center;
  border: 2px solid #303c54;
  margin: 5px;
  align-items: center;
  border-radius: 0.25rem;
}
.part.active {
  background-color: #2F3C54;
  color: white;
}

.part p {
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
