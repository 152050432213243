<template>
  <CRow>
    <CCol col="6" sm="6">
      <CWidgetIcon :header="productStatistics.products" text="Total Products" color="primary">
        <CIcon name="cil-settings" />
      </CWidgetIcon>
    </CCol>
    <CCol col="6" sm="6">
      <CWidgetIcon :header="productStatistics.availableProducts" text="Available Products" color="success">
        <CIcon name="cil-laptop" />
      </CWidgetIcon>
    </CCol>
    <CCol col="12" xl="12">
      <CButton variant="outline" type="submit" color="primary" class="list-new-btn"
        @click="$router.push({ path: '/manager/bookings/create' })">
        <CIcon name="cil-check-circle" />New booking
      </CButton>

      <transition name="slide">
        <CCard>
          <CCardHeader>
            <CCardTitle>Bookings</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CDataTable hover :table-filter="{
              external: true,
            }" @update:table-filter-value="tableFilter" striped index-column :items="bookings" :fields="fields">

              <template #location="{ item }">
                <td class="py-2">
                  {{ item.location.name }}
                </td>
              </template>

              <template #customer="{ item }">
                <td class="py-2">
                  {{ item.customer_name }}
                </td>
              </template>

              <template #products="{ item }">
                <td class="py-2">
                  <span v-for="row in item.booking_rows" :key="row.id">
                    <CBadge v-if="row.return_state == 'unreturned' && row.product" class="booking-product"
                      :class="{ 'time-alarm': new Date() > new Date(row.till) }" color="warning">
                      <span>{{ row.product.type }} #{{ row.product.number }}<strong
                          v-if="new Date() > new Date(row.till)"> - LATE</strong></span>
                    </CBadge>
                    <CBadge v-else-if="row.return_state == 'returned'" class="booking-product" color="success">
                      <span v-if="row.product">{{ row.product.type }} #{{ row.product.number }}</span>
                    </CBadge>
                    <CBadge v-else class="booking-product" color="danger">
                      <span v-if="row.product">{{ row.product.type }} #{{ row.product.number }}</span>
                    </CBadge>
                  </span>
                </td>
              </template>

              <template #total_price_inc="{ item }">
                <td class="py-2">€ {{ item.total_price_inc }}</td>
              </template>

              <template #state="{ item }">
                <td>
                  <CBadge :color="getBadge(item.state)">
                    {{ item.state }}
                  </CBadge>
                </td>
              </template>
              <template #actions="{ item, index }">
                <td class="py-2">
                  <CButton v-if="['unbilled', 'billed'].includes(item.state)" @click="showReturns(item)" color="success"
                    variant="outline" square size="sm" class="mb-1">
                    Returns
                  </CButton>
                  <CButton @click="detailClicked(item, index)" color="primary" variant="outline" square size="sm"
                    class="mb-1">
                    Details
                  </CButton>
                  <CButton v-if="['requested'].includes(item.state)" @click="accept(item.id)" color="success"
                    variant="outline" square size="sm" class="mb-1">
                    Accept
                  </CButton>
                  <CButton v-if="['requested'].includes(item.state)" @click="deny(item.id)" color="danger"
                    variant="outline" square size="sm" class="mb-1">
                    Deny
                  </CButton>
                </td>
              </template>

            </CDataTable>
            <CPagination :active-page.sync="pagination.activePage" :pages="pagination.pages"
              @update:activePage="tableChangePage" />
          </CCardBody>
        </CCard>
      </transition>
    </CCol>

    <CModal :show.sync="showReturnModal" :no-close-on-backdrop="true" :centered="true" size="lg" color="primary">
      <template #header>
        <h2 class="modal-title">Process Returns</h2>
        <CButtonClose @click="closeReturns" class="text-white" />
      </template>

      <div v-if="returnBookingIndex !== false">
        <CRow v-for="booking_row in returnBooking.booking_rows" :key="booking_row.id">
          <CCol sm="2">
            <p><strong>Product</strong></p>
            <p v-if="booking_row.product">
              {{ booking_row.product.type }} - #{{ booking_row.product.number }}
            </p>
          </CCol>
          <CCol sm="3">
            <p><strong>From / Till</strong></p>
            <p v-if="booking_row.product">
              {{ booking_row.from }} - {{ booking_row.till }}
            </p>
          </CCol>

          <CCol sm="3">
            <p><strong>Return State</strong></p>
            <CSelect :options="[
              { label: 'Returned', value: 'returned' },
              { label: 'Unreturned', value: 'unreturned' }
            ]" @update:value="booking_row.return_state = $event" :value.sync="booking_row.return_state" />
            <CRow class="mb-4" sm="12" v-if="booking_row.defects.length > 0">
              <CCol sm="12">
                <span><strong>Defects</strong></span>
              </CCol>
              <CCol sm="12" v-for="defect in booking_row.defects" :key="defect.id">
                {{ defect.part.name }}
              </CCol>
            </CRow>

            <CButton @click="defect_booking_row = booking_row" variant="outline" size="sm" color="primary">
              Add Defects
            </CButton>
            <Defect v-if="defect_booking_row == booking_row" :show="true" :data="defect_booking_row" @input="
              booking_row.defects.push($event);
            booking_row.return_state = 'broken';
            " @hide="defect_booking_row = null"></Defect>
          </CCol>

          <CCol sm="4">
            <p><strong>Product Note</strong></p>
            <CTextarea description="This note will be placed on the product details" v-model="booking_row.product.note" />
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton @click="closeReturns" color="danger">Cancel</CButton>
        <CButton @click="processReturns" color="success">Process</CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import BookingAPI from "/app/src/api/booking.js"
import ProductAPI from "/app/src/api/product.js"

import Defect from "./Defect"

export default {
  name: "Bookings",
  components: { Defect },
  data: () => {
    return {
      bookingAPI: new BookingAPI(),
      productAPI: new ProductAPI(),
      bookings: [],
      productStatistics: {
        products: "0",
        availableProducts: "0",
      },
      fields: [
        { key: "id", display: "none" },
        { key: "created_at", label: "Booking Date" },
        { key: "from" },
        { key: "till" },
        { key: "customer", label: "Customer" },
        { key: "location_name", label: "Location" },
        { key: "products" },
        { key: "total_price_inc" },
        { key: "state" },
        { key: "actions" },
      ],
      pagination: {
        activePage: 0,
        itemsPerPage: 20,
        pages: 0,
        totalItems: 0
      },
      filter: "",
      showReturnModal: false,
      showDefectsModal: false,
      defect_booking_row: null,
      returnBookingIndex: false,
      returnBooking: {},
    }
  },
  created: function () {
    this.getBookings(1)
    this.getProductStatistics()
  },
  methods: {
    getBookings: function () {
      console.log('getBookings')
      console.log(this.filter)
      this.$store.dispatch("loading")
      let self = this
      this.bookingAPI
        .all(this.filter, this.pagination.activePage)
        .then((data) => {
          for (let i = 0; i < data.data.length; i++) {
            if (data.data[i].customer.name) {
              data.data[i].customer_name =
                data.data[i].customer.name + "<br>" + data.data[i].note
            } else if (data.data[i].mews_customer_id) {
              data.data[i].customer_name = `${data.data[i].mews_customer_name ?? ''} (Mews)  ${data.data[i].note}`
            } else {
              data.data[i].customer_name = "Unknown"
            }
            data.data[i].location_name = data.data[i].location.name
            for (let j = 0; j < data.data[i].booking_rows.length; j++) {
              if (data.data[i].booking_rows[j].product) {
                data.data[i].products +=
                  data.data[i].booking_rows[j].product.type +
                  " #" +
                  data.data[i].booking_rows[j].product.number
              }
            }
          }
          this.$store.dispatch("stopLoading")
          self.bookings = data.data
          self.pagination.totalItems = data.total
          self.pagination.pages = data.last_page

        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
          this.$alert.show({ type: "danger", message: error })
        })
    },

    tableFilter(filter) {
      this.pagination.activePage = 1
      this.filter = filter
      this.getBookings()
    },

    tableChangePage(changePage) {
      this.pagination.activePage = changePage
      this.getBookings()
    },

    detailClicked(item, index) {
      const itemLink = `/manager/bookings/${item.id.toString()}`
      this.$router.push({ path: itemLink })
    },

    showReturns(booking) {
      // Copy the booking
      for (var i = 0; i < this.bookings.length; i++) {
        if (booking.id == this.bookings[i].id) {
          this.returnBooking = JSON.parse(JSON.stringify(this.bookings[i]))
          this.returnBookingIndex = i
          this.showReturnModal = true
        }
      }
    },

    returnStateUpdated(booking_row) {
      if (booking_row.return_state == "broken") {
        this.defectBookingRow = booking_row
        this.showDefectModal = true
      }
    },

    processReturns() {
      this.$store.dispatch("loading")
      var self = this
      this.bookingAPI
        .processReturns(this.returnBooking)
        .then((booking) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({
            type: "success",
            message: "Returns have been processed",
          })
          if (booking.customer) {
            booking.customer_name = booking.customer.name
          } else if (booking.mews_customer_id) {
            booking.customer_name = booking.mews_customer_name
          }
          booking.location_name = booking.location.name
          self.$set(self.bookings, self.returnBookingIndex, booking)
          self.closeReturns()
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
          console.log(error)
          this.closeReturns()
        })
    },

    closeReturns() {
      this.showReturnModal = false
    },

    getProductStatistics() {
      let today = new Date()
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate()
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
      let dateTime = date + "T" + time

      if (this.$store.getters.isAdmin) {
        var location_id = 0
      } else {
        var location_id = this.$store.getters.user.location_id
      }

      this.productAPI
        .availableProducts(dateTime, dateTime, location_id)
        .then((products) => {
          this.productStatistics.availableProducts = products.length.toString()
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
        })

      this.productAPI
        .all()
        .then((products) => {
          this.productStatistics.products = products.length.toString()
          this.$store.dispatch("stopLoading")
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          console.log(error)
        })
    },


    accept(id) {
      this.bookingAPI
        .accept(id)
        .then(() => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({
            type: "success",
            message: "Booking has been accepted",
          })

          this.bookings.forEach((item) => {
            if (item.id == id) {
              item.state = "unbilled"
            }
          })
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
          console.log(error)
        })
    },

    deny(id) {
      this.bookingAPI
        .deny(id)
        .then(() => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({
            type: "success",
            message: "Booking has been denied",
          })
          this.bookings.forEach((item) => {
            if (item.id == id) {
              item.state = "denied"
            }
          })
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading")
          this.$alert.show({ type: "danger", message: error })
          console.log(error)
        })
    },

    getBadge(state) {
      var colors = {
        unbilled: "warning",
        billed: "success",
        requested: "primary",
        pending: "primary",
        accepted: "success",
        denied: "secondary",
        broken: "warning",
        returned: "info",
      }
      return colors[state]
    },
  },
}
</script>

<style scoped>
.booking-product {
  margin-right: 5px !important;
}

.time-alarm {
  animation: pulse 1.5s infinite;
  background-color: #F9B114;
}

@keyframes pulse {

  0%,
  10% {
    opacity: 0.9;
  }

  20% {
    opacity: 0.85;
  }

  30% {
    opacity: 0.8;
  }

  40% {
    opacity: 0.75;
  }

  50% {
    opacity: 0.7;
  }

  60% {
    opacity: 0.75;
  }

  70% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.85;
  }

  90% {
    opcatiy: 0.9;
  }

  100% {
    opcatiy: 1;
  }
}
</style>
